import React, { useState, useEffect } from 'react';
import TopCharacter1 from '../../../assets/Home/topcharacter1.jpeg'
import TopCharacter2 from '../../../assets/Home/topcharacter2.jpeg'
import TopCharacter3 from '../../../assets/Home/topcharacter3.jpeg'
import TopCharacter4 from '../../../assets/Home/topcharacter4.jpeg'
import TopCharacter5 from '../../../assets/Home/topcharacter5.jpeg'
import TopCharacter6 from '../../../assets/Home/topcharacter6.jpeg'
import TopCharacter7 from '../../../assets/Home/topcharacter7.jpeg'
import TopCharacter8 from '../../../assets/Monkeys/monkey5.JPG'
import TopCharacter9 from '../../../assets/Monkeys/monkey8.JPG'
import TopCharacter10 from '../../../assets/Monkeys/monkey1.JPG'
import TopCharacter11 from '../../../assets/Monkeys/monkey4.JPG'
import TopCharacter12 from '../../../assets/Monkeys/monkey22.png'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { motion } from "framer-motion"
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function TopCharacters() {
    const [showmore, setShowmore] = useState(false)
    const [loader, setLoader] = useState(false)
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 769 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 1
        }
    };


    const { ref, inView } = useInView({
        threshold: 0.1
    });
    const animation = useAnimation();

    const topCharacter = [
        {
            image: TopCharacter1,
            title: 'THE MONKATAR',
            eth: '130ETH',
            desc: '',
            tagStatus: 'character-label-body'
        },
        {
            image: TopCharacter7,
            title: 'THE FREDDY MONKEY',
            eth: '130ETH',
            desc: ' ',
            tagStatus: 'character-label-body'
        },
        {
            image: TopCharacter3,
            title: 'THE UNCLE T',
            eth: '130ETH',
            desc: ' ',
            tagStatus: 'character-label-body3'
        },
        {
            image: TopCharacter8,
            title: 'THE MONKEY OF WALL STREET',
            eth: '130ETH',
            desc: ' ',
            tagStatus: 'character-label-body2'
        },
        {
            image: TopCharacter9,
            title: 'THE DJ',
            eth: '130ETH',
            desc: ' ',
            tagStatus: 'character-label-body2'
        },
        {
            image: TopCharacter2,
            title: 'THE MONKEY BAE',
            eth: '130ETH',
            desc: ' ',
            tagStatus: 'character-label-body2'
        },
        {
            image: TopCharacter10,
            title: 'THE JIGMONKEY',
            eth: '130ETH',
            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting...',
            tagStatus: 'character-label-body'
        },
        {
            image: TopCharacter11,
            title: 'THE YODA',
            eth: '130ETH',
            desc: '',
            tagStatus: 'character-label-body'
        },
        {
            image: TopCharacter6,
            title: 'THE MASK',
            eth: '130ETH',
            desc: ' ',
            tagStatus: 'character-label-body3'
        },
        {
            image: TopCharacter12,
            title: 'THE CYLOPSTOR',
            eth: '130ETH',
            desc: ' ',
            tagStatus: 'character-label-body3'
        },

    ];

    const ShowmoreFunction = (flag) => {
        setLoader(true)
        setTimeout(() => { setShowmore(flag); setLoader(false) }, 1000)
    }

    useEffect(() => {
        if (inView) {
            animation.start({
                x: 0,
                transition: {
                    type: 'spring', duration: 1, bounce: 0.3
                }
            })
        }
        if (!inView) {
            animation.start({ x: '-200vh' })
        }
    }, [inView])

    return (
        <div id="section3" className="creativeteam-container2">

            <div className="creativeteam-header">
                <div className="creativeheader-top">SPECIAL</div>
                <div className="creativeheader-top2">MONKEY</div>
            </div>
            <div className="character-desc set-desc">
                The Specials Monkeys are the 10 special Cyclops Monkeys that will enable their holders to earn $10,000 each in ETH. They are the ones that will lead our combat to success, therefore they will have a huge role in the development of our community. Once the Cyclops Monkeys are sold out, the owners will be reward.
            </div>

            <Carousel
                ssr
                swipeable
                responsive={responsive}
                itemClass="carousel-item-padding-10-px"
                infinite={true}
                autoPlay
                pauseOnHover={false}
                autoPlaySpeed={2000}
                arrows={false}
                className="topcharacter-body"
            >

                {
                    // topCharacter.map((v, i) => {
                    //     if (showmore) {
                    //         return (
                    //             <div
                    //                 className="topcharacter-container">
                    //                 <img className="topcharacter-image" src={v.image} alt="Top Character" />
                    //                 <div className="character-titleheader">
                    //                     <div className="character-title-text">{v.title}</div>
                    //                     {/* <div className={v.tagStatus}>
                    //                         <div className="character-label-text">{v.eth}</div>
                    //                     </div> */}
                    //                 </div>
                    //                 <div className="character-seperator"></div>
                    //                 {/* <div className="character-desc">
                    //                     Lorem Ipsum is simply dummy text of the printing and typesetting...
                    //                 </div> */}
                    //             </div>
                    //         )
                    //     }
                    //     else {
                    //         return (
                    //             <div>
                    //                 {
                    //                     i <= 2 ? (
                    //                         <div
                    //                              animate={animation}
                    //                              className="topcharacter-container">
                    //                             <img className="topcharacter-image" src={v.image} alt="Top Character" />
                    //                             <div className="character-titleheader">
                    //                                 <div className="character-title-text">{v.title}</div>
                    //                                 {/* <div className={v.tagStatus}>
                    //                                     <div className="character-label-text">{v.eth}</div>
                    //                                 </div> */}
                    //                             </div>
                    //                             <div className="character-seperator"></div>
                    //                             {/* <div className="character-desc">
                    //                                 Lorem Ipsum is simply dummy text of the printing and typesetting...
                    //                             </div> */}
                    //                         </div>
                    //                     ) : null
                    //                 }
                    //             </div>
                    //         )
                    //     }
                    // })

                }

                {
                    topCharacter.map((v, i) => (
                        <div
                            className="maintain-character"
                            >
                            <img className="topcharacter-image" src={v.image} alt="Top Character" />
                            <div className="character-titleheader">
                                <div className="character-title-text">{v.title}</div>
                            </div>
                            <div className="character-seperator"></div>

                        </div>
                    ))
                }



            </Carousel>
            {/* 
            {
                showmore ?
                    (
                        <div>
                            {
                                loader ? (
                                    <Loader
                                        type="Puff"
                                        style={{ margin: '50px' }}
                                        color="#F24F60"
                                        height={100}
                                        width={100}
                                    />
                                ) : (
                                    <div className="character-showmore-body" onClick={() => ShowmoreFunction(false)}>
                                        <div className="character-showmore-button">
                                            <div className="character-showmore-button-text">SHOW LESS</div>
                                        </div>
                                    </div>
                                )
                            }

                        </div>
                    ) : (
                        <div>
                            {
                                loader ? (
                                    <Loader
                                        type="Puff"
                                        style={{ margin: '50px' }}
                                        color="#F24F60"
                                        height={100}
                                        width={100}
                                    />
                                ) : (
                                    <div className="character-showmore-body" onClick={() => ShowmoreFunction(true)}>
                                        <div className="character-showmore-button">
                                            <div className="character-showmore-button-text">SHOW MORE</div>
                                        </div>
                                    </div>
                                )
                            }

                        </div>
                    )
            } */}

        </div>
    )
}

export default TopCharacters
