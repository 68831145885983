import React, {useEffect} from 'react';
import JoinCommunityImage from '../../../assets/Home/joinCommunity.png'
import { motion } from "framer-motion"
import {useInView} from 'react-intersection-observer';
import {useAnimation} from 'framer-motion'

function JoinCommunity({  }) {
    const {ref, inView} = useInView({
        threshold:0.2
    });
    const animation = useAnimation();

    useEffect(() => {
        if(inView){
            animation.start({
                x:0,
                transition:{
                    type: 'spring', duration:1, bounce:0.3
                }
            })
        }
        if(!inView){
            animation.start({x: '100vh'})
        }
    }, [inView])
    return (
        <div id="section5" className="joinCommunity-container" ref={ref} >
            <div className="joincommunity-innercontainer">
                <div className="joincommunity-topheader">JOIN OUR</div>
                <div className="joincommunity-innerheader">COMMUNITY</div>
                <div className="joincommunity-desc">Together, let’s build the best exclusive club never seen before. Show to the world how strong and influent we are. Let’s take over the Metaverse.</div>
                <div className="joincommunity-buttoncontainer">
                    <a href="https://discord.com/invite/YFdUUYZ3yh" target="_target" className="header-button">
                        <div className="header-descord">JOIN OUR DISCORD</div>
                    </a>
                    {/* <a href="https://discord.com/invite/YFdUUYZ3yh" target="_target" className="joincommunity-button">
                        <div className="joincommunity-button-desc">JOIN OUR DISCORD</div>
                    </a> */}
                </div>
            </div>
            {/* <motion.div animate={{ scale: 0.5 }} style={{ top: Math.pow(scroll * 20, 2) > 100 ? Math.pow(scroll * 20, 2) : 100}} className="joincommunity-image-body"> */}
            <motion.div 
            animate={animation}
            className="joincommunity-image-body"
            >
                <img className="joincommunity-image" src={JoinCommunityImage} alt="Community Image" />
            </motion.div>
        </div>
    )
}

export default JoinCommunity
