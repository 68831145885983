import React, { useEffect } from "react";
import './Drawer.css'
import { motion } from "framer-motion"
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion'
import { IoMdClose } from 'react-icons/io'
import Instagram from '../../assets/instagram.png'
import Crabs from '../../assets/crabs.png'
import Boat from '../../assets/boat.png'
import Twitter from '../../assets/twitter.png'
import { HashLink } from 'react-router-hash-link';


export default function Drawer({ state }) {
    const { drawer, setDrawer } = state
    const { ref, inView } = useInView({
        threshold: 0.1
    });
    
    const animation = useAnimation();

    useEffect(() => {
        if (drawer) {
            animation.start({
                x: 0,
                transition: {
                    type: 'spring', duration: 1, bounce: 0.3
                }
            })
        }
        if (!drawer) {
            animation.start({ x: '500vh' })
        }
    }, [drawer])

    return (
        <div >
            <motion.div animate={animation} className={drawer &&  document.documentElement.scrollWidth >= 768 ? "drawer-container" : 'drawer-container hide'}>
                <div className="drawer-innercontainer1"></div>
                <div className="drawer-innercontainer2">
                    <div className="drawer-buttonContainer1">
                        <div onClick={() => setDrawer(false)} className="drawer-closeButton">
                            <IoMdClose size={40} color="white" />
                        </div>
                        {/* <div className="drawer-titleButton">JOIN OUR DISCORD</div> */}
                    </div>

                    <HashLink onClick={() => setDrawer(false)} smooth to='/#section1' className="drawer-items">ABOUT</HashLink >
                    <HashLink onClick={() => setDrawer(false)} smooth to='/#section2' className="drawer-items">ROADMAP</HashLink >
                    <HashLink onClick={() => setDrawer(false)} smooth to='/#section3' className="drawer-items">SPECIAL MONKEYS</HashLink >
                    <HashLink onClick={() => setDrawer(false)} smooth to='/#section4' className="drawer-items">TEAM</HashLink >
                    <HashLink onClick={() => setDrawer(false)} smooth to='/#section5' className="drawer-items">COMMUNITY</HashLink >
                    <HashLink onClick={() => setDrawer(false)} smooth to='/#section6' className="drawer-items">FAQ</HashLink >

                    <div className="drawer-buttonContainer">

                        <a href="https://twitter.com/cyclops_monkey" target="_target">
                            <img className="social-icons" src={Twitter} alt="Instagram" />
                        </a>

                        <a href="https://discord.com/invite/YFdUUYZ3yh" target="_target">
                            <img className="social-icons" src={Crabs} alt="Instagram" />
                        </a>

                        <a href="https://opensea.io/Cyclops_Monkey_Club" target="_target">
                            <img className="social-icons" src={Boat} alt="Instagram" />
                        </a>

                        <a href="https://www.instagram.com/cyclopsmonkey/" target="_target">
                            <img className="social-icons" src={Instagram} alt="Instagram" />
                        </a>

                    </div>
                </div>
            </motion.div>


            <motion.div animate={animation} className={drawer &&  document.documentElement.scrollWidth < 768 ? "drawer-container" : 'drawer-container hide'}>
                <div className="drawermobile-innercontainer2">
                    <div className="drawer-buttonContainer1">
                        <div onClick={() => setDrawer(false)} className="drawer-closeButton">
                            <IoMdClose size={40} color="white" />
                        </div>
                        {/* <a  href="https://discord.com/invite/YFdUUYZ3yh" target="_target" className="drawer-titleButton">JOIN OUR DISCORD</a> */}
                    </div>

                    <HashLink onClick={() => setDrawer(false)} smooth to='/#section1' className="drawer-items">ABOUT</HashLink >
                    <HashLink onClick={() => setDrawer(false)} smooth to='/#section2' className="drawer-items">ROADMAP</HashLink >
                    <HashLink onClick={() => setDrawer(false)} smooth to='/#section3' className="drawer-items">SPECIAL MONKEYS</HashLink >
                    <HashLink onClick={() => setDrawer(false)} smooth to='/#section4' className="drawer-items">TEAM</HashLink >
                    <HashLink onClick={() => setDrawer(false)} smooth to='/#section5' className="drawer-items">COMMUNITY</HashLink >
                    <HashLink onClick={() => setDrawer(false)} smooth to='/#section6' className="drawer-items">FAQ</HashLink >

                    <div className="drawer-buttonContainer">

                        <a href="https://twitter.com/cyclops_monkey" target="_target">
                            <img className="social-icons" src={Twitter} alt="Instagram" />
                        </a>

                        <a href="https://discord.com/invite/YFdUUYZ3yh" target="_target">
                            <img className="social-icons" src={Crabs} alt="Instagram" />
                        </a>

                        <a href="https://opensea.io/Cyclops_Monkey_Club" target="_target">
                            <img className="social-icons" src={Boat} alt="Instagram" />
                        </a>

                        <a href="https://www.instagram.com/cyclopsmonkey/" target="_target">
                            <img className="social-icons" src={Instagram} alt="Instagram" />
                        </a>

                    </div>
                </div>
            </motion.div>
        </div>
    );
}