import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Image1 from '../../../assets/Home/carousel/carousel1.png'
import Image2 from '../../../assets/Home/carousel/carousel2.png'
import Image3 from '../../../assets/Home/carousel/carousel3.png'
import Image4 from '../../../assets/Home/carousel/carousel4.png'
import Monkey17 from '../../../assets/Monkeys/monkey17.jpeg'
import Monkey18 from '../../../assets/Monkeys/monkey18.jpeg'
import Monkey19 from '../../../assets/Monkeys/monkey19.jpeg'
import Monkey20 from '../../../assets/Monkeys/monkey20.jpeg'
import Monkey21 from '../../../assets/Monkeys/monkey21.jpeg'

function CyclopsGallery() {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <div className="cyclops-container">
            <div className="cyclops-innercontainer">
                <div >
                    <div className="roadmap-header cyclops-header">
                        <div className="roadmap-header1">CYCLOPS</div>
                        <div className="roadmap-header2">Gallery</div>
                    </div>
                </div>

                <div className="cyclops-carousel-body">
                    <Carousel
                        autoPlay={true}
                        itemClass="carousel-item-padding-40-px"
                        infinite
                        autoPlaySpeed={1000}
                        showDots={false}
                        swipeable={true}
                        draggable
                        responsive={responsive}>

                        <div className="carousel-image-body">
                            <img className="carousel-image" src={Image1} alt="Image1" />
                        </div>

                        <div className="carousel-image-body">
                            <img className="carousel-image" src={Image2} alt="Image2" />
                        </div>

                        <div className="carousel-image-body">
                            <img className="carousel-image" src={Image3} alt="Image3" />
                        </div>

                        <div className="carousel-image-body">
                            <img className="carousel-image" src={Image4} alt="Image4" />
                        </div>

                        <div className="carousel-image-body">
                            <img className="carousel-image adjust-images" src={Monkey17} alt="Image1" />
                        </div>

                        <div className="carousel-image-body">
                            <img className="carousel-image adjust-images" src={Monkey18} alt="Image2" />
                        </div>

                        <div className="carousel-image-body">
                            <img className="carousel-image adjust-images" src={Monkey19} alt="Image2" />
                        </div>

                        <div className="carousel-image-body">
                            <img className="carousel-image adjust-images" src={Monkey20} alt="Image2" />
                        </div>

                        <div className="carousel-image-body">
                            <img className="carousel-image adjust-images" src={Monkey21} alt="Image2" />
                        </div>

                    </Carousel>

                    <div className="cyclops-button-container">
                        <div className="cyclops-carousel-button">
                            <div className="cyclops-carousel-button-text">VIEW ALL</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CyclopsGallery
