import React, { useState, useEffect } from 'react';
import './home.css';
import LandingScreen from '../../assets/Home/landingImage.png'
import MobileScreen from '../../assets/Home/mobile-image.png'
import UniqueGhose from './Components/UniqueGhose';
import RoadMap from './Components/RoadMap';
import CreativeTeam from './Components/CreativeTeam'
import JoinCommunity from './Components/JoinCommunity'
import CyclopsGallery from './Components/CyclopsGallery'
import TopCharacters from './Components/TopCharacters'
import FrequentlyAsked from './Components/FrequentlyAsked'
import Monkey1 from '../../assets/Monkeys/monkey1.JPG'
import Monkey2 from '../../assets/Monkeys/monkey2.JPG'
import Monkey3 from '../../assets/Monkeys/monkey3.JPG'
import Monkey4 from '../../assets/Monkeys/monkey4.JPG'
import Monkey5 from '../../assets/Monkeys/monkey5.JPG'
import Monkey6 from '../../assets/Monkeys/monkey6.JPG'
import Monkey7 from '../../assets/Monkeys/monkey7.JPG'
import Monkey8 from '../../assets/Monkeys/monkey8.JPG'
import Monkey9 from '../../assets/Monkeys/monkey9.jpg'
import Monkey10 from '../../assets/Monkeys/monkey10.jpeg'
import Monkey11 from '../../assets/Monkeys/monkey11.jpeg'
import Monkey12 from '../../assets/Monkeys/monkey12.jpeg'
import Monkey13 from '../../assets/Monkeys/monkey13.jpeg'
import Monkey14 from '../../assets/Monkeys/monkey14.jpeg'
import Monkey15 from '../../assets/Monkeys/monkey15.jpeg'
import Monkey16 from '../../assets/Monkeys/monkey16.jpeg'
import Monkey17 from '../../assets/Monkeys/monkey17.jpeg'
import Monkey18 from '../../assets/Monkeys/monkey18.jpeg'
import Monkey19 from '../../assets/Monkeys/monkey19.jpeg'
import Monkey20 from '../../assets/Monkeys/monkey20.jpeg'
import Monkey21 from '../../assets/Monkeys/monkey21.jpeg'

function Home({}) {
    const [scrollPosition, setScrollPosition] = useState(0);
    const screenWidth = document.documentElement.clientWidth
    

    const listenToScroll = () => {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight
        const scrolled = winScroll / height

        setScrollPosition(scrolled)
    }

    useEffect(() => {
        window.addEventListener('scroll', listenToScroll)

        return () => {
            window.removeEventListener('scroll', listenToScroll)
        };
    }, []);
    return (
        <div className="home-maincontainer">

            <div className="home-image">
                <img className="home-cover-image" src={document.documentElement.scrollWidth >= 429 ? LandingScreen : MobileScreen} />
            </div>

            <div className="manage-slider-movement">

                <div style={{ marginLeft: document.documentElement.scrollWidth >= 768 ? -1800 + (scrollPosition * screenWidth * 10) : -800 + (scrollPosition * screenWidth * 10) }} className="slider-container">
                {/* <div style={{ transform: `translate3d(${-1200 + (scrollPosition * screenWidth * 10)}px,0px,0px)` }} className="slider-container"> */}

                    <div className="slider-inner-container">
                        <img className="slider-inner-image" src={Monkey1} alt="Monkey Images" />
                    </div>

                    <div className="slider-inner-container">
                        <img className="slider-inner-image" src={Monkey2} alt="Monkey Images" />
                    </div>

                    <div className="slider-inner-container">
                        <img className="slider-inner-image" src={Monkey3} alt="Monkey Images" />
                    </div>

                    <div className="slider-inner-container">
                        <img className="slider-inner-image" src={Monkey4} alt="Monkey Images" />
                    </div>

                    <div className="slider-inner-container">
                        <img className="slider-inner-image" src={Monkey5} alt="Monkey Images" />
                    </div>

                    <div className="slider-inner-container">
                        <img className="slider-inner-image" src={Monkey6} alt="Monkey Images" />
                    </div>

                    <div className="slider-inner-container">
                        <img className="slider-inner-image" src={Monkey7} alt="Monkey Images" />
                    </div>

                    <div className="slider-inner-container">
                        <img className="slider-inner-image" src={Monkey8} alt="Monkey Images" />
                    </div>

                    <div className="slider-inner-container">
                        <img className="slider-inner-image" src={Monkey9} alt="Monkey Images" />
                    </div>

                    <div className="slider-inner-container">
                        <img className="slider-inner-image" src={Monkey10} alt="Monkey Images" />
                    </div>
                </div>

                <div className="manage-slider2"  >
                    <div style={{ marginLeft: -500 - (scrollPosition * screenWidth * 4)}} className="slider-container">

                        <div className="slider-inner-container">
                            <img className="slider-inner-image" src={Monkey11} alt="Monkey Images" />
                        </div>

                        <div className="slider-inner-container">
                            <img className="slider-inner-image" src={Monkey12} alt="Monkey Images" />
                        </div>

                        <div className="slider-inner-container">
                            <img className="slider-inner-image" src={Monkey13} alt="Monkey Images" />
                        </div>

                        <div className="slider-inner-container">
                            <img className="slider-inner-image" src={Monkey14} alt="Monkey Images" />
                        </div>

                        <div className="slider-inner-container">
                            <img className="slider-inner-image" src={Monkey15} alt="Monkey Images" />
                        </div>

                        <div className="slider-inner-container">
                            <img className="slider-inner-image" src={Monkey16} alt="Monkey Images" />
                        </div>

                        <div className="slider-inner-container">
                            <img className="slider-inner-image" src={Monkey17} alt="Monkey Images" />
                        </div>

                        <div className="slider-inner-container">
                            <img className="slider-inner-image" src={Monkey18} alt="Monkey Images" />
                        </div>

                        <div className="slider-inner-container">
                            <img className="slider-inner-image" src={Monkey19} alt="Monkey Images" />
                        </div>

                        <div className="slider-inner-container">
                            <img className="slider-inner-image" src={Monkey20} alt="Monkey Images" />
                        </div>
                    </div>
                </div>
                

            </div>

            <UniqueGhose />

            <RoadMap />

            <TopCharacters />

            <CreativeTeam />

            <JoinCommunity />

            {/* <CyclopsGallery /> */}

            <FrequentlyAsked />

        </div>
    )
}

export default Home
