import React, { useState, useEffect } from 'react'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { motion } from "framer-motion"
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion'
import Roadmap from '../../../assets/Home/footer.png'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { BiPlus } from 'react-icons/bi'

function RoadMap() {
    const [readmore, setReadmore] = useState(false)
    const [loader, setLoader] = useState(false)


    const { ref, inView } = useInView({
        threshold: 0.1
    });
    const animation = useAnimation();

    const obj = [
        {
            percent: 0,
            heading: 'Welcome to all our members! Be patient, the ship is filling up!',
            desc: "In the meantime, let’s play. Our first lottery will take place with our first members. We need to fill the whitelist, and in order to do so, everyone needs to show their enthusiasm around the project!"
        },
        {
            percent: 25,
            heading: 'Next step, to the moon! ',
            desc: "At 10K members we will randomly select 5 persons to earn 1 Cyclops Monkey NFT. You’ll be notified when the time comes! At 20K members, a new game of challenges will be done: you’ll have the chance to win Cyclops Monkey NFTs. To reach this stage we must all work together hand in hand in solidarity to make our community prosper."
        },
        {
            percent: 50,
            heading: 'A community of leaders!',
            desc: "Showcase of the 10 special Cyclops Monkeys that will enable their holders to earn $10,000 each in ETH. They are the ones that will lead our combat to success, therefore they will have a huge role in the development of our community. Showcase of our Legendary Cyclops Monkey: $200,000 in ETH will be given to the holder, to ensure all of his future investments, or to just secure his financial stability. It is him alongside Polyfimos who will lead the Cyclops Monkey Club to keep peace among us."
        },
        {
            percent: 75,
            heading: 'Get ready, we are here!',
            desc: "Be present that day! The opportunity is real. The Cyclops Monkeys are awakening and joining the Cyclops Monkeys club. You have no excuse to miss this long awaited event."
        },
        {
            percent: 100,
            heading: 'Time to shine',
            desc: "The Legendary & the special Cyclops Monkeys will be paid for their loyalty to their team. We will allocate another $100K budget for community development and marketing. Our influencers are just waiting for us to push a massive wave of communication! Cyclops monkeys are going to take over the biggest cities and the whole entire world, so get connected and keep your eyes open! A huge party will be organized in the city of your choice, that will be done through a Discord vote! Famous guests will be invited, some of whom have pushed the project, so that we can all meet each other and expand our network with the biggest entrepreneurs and celebrities from all over the world. There will be a draw that evening for one of you to win a car! The model of the car will be voted on Discord. The main goal of this evening will be to strengthen our community and grow our members. We will also be sponsoring the world's biggest sporting events such as UFC fights, the Superbowl and NBA games to increase our exposure."
        },
    ]
    const triggerFunction = (flag) => {
        setLoader(true)
        setTimeout(() => {
            setReadmore(flag)
            setLoader(false)
        }, 2000)
    }
    useEffect(() => {
        if (inView) {
            animation.start({
                x: 0,
                transition: {
                    type: 'spring', duration: 1.5, bounce: 0.3
                }
            })
        }
        if (!inView) {
            animation.start({ x: '-100vh' })
        }
    }, [inView])

    return (
        <div ref={ref} id="section2" >

            <div className="roadmap-subcontainer">
                <div className="roadmap-header">
                    <div className="roadmap-header1">LAUNCH</div>
                    <div className="roadmap-header2">ROADMAP</div>
                </div>
            </div>

            <div className="roadmap-launchcontainer">
                <motion.div animate={animation} className="roadmap-innerlaunch1">
                    <img className="roadmap-image" src={Roadmap} alt="RoadMap" />
                </motion.div>
                <div className="roadmap-innerlaunch2">

                    <Accordion
                        allowZeroExpanded={true}
                        allowMultipleExpanded
                        className="roadmap-body">

                        {
                            obj.map((v, i) => {
                                return (
                                    <AccordionItem>
                                        <AccordionItemHeading >
                                            <AccordionItemButton className="roadmap-item-container">
                                                <div className="roadmap-percentage">
                                                    <div className="roadmap-percent">{v.percent}%</div>
                                                </div>
                                                <div className="roadmap-title">
                                                    {v.heading}
                                                </div>
                                                <BiPlus size={30} color='white' />
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p className="topcharacter-desc2">
                                                {v.desc}
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                )
                            })
                        }

                    </Accordion>

                </div>
            </div>
            {
                // <div ref={ref}>
                //     {
                //         loader ?
                //             <div className="loader-container">
                //                 <Loader
                //                     type="Puff"
                //                     color="#F24F60"
                //                     height={100}
                //                     width={100}
                //                 />
                //             </div>
                //             :
                //             obj.map((v, i) => {
                //                 if (readmore) {
                //                     if (i % 2 == 0) {
                //                         return <div className="roadmap-container">
                //                             <motion.div animate={animation} className="maintain-container">
                //                                 {
                //                                     i === 0 ? 
                //                                     (
                //                                             <div className="roadmap-header">
                //                                                 <div className="roadmap-header1">LAUNCH</div>
                //                                                 <div className="roadmap-header2">Roadmap</div>
                //                                             </div>
                //                                     ) : null
                //                                 }
                //                                 <div className="roadmap-percent">{v.percent}%</div>
                //                                 <div className="roadmap-subhead">{v.heading}</div>
                //                                 <div className="ghost-desc-text">{v.desc}</div>
                //                                 <div className="container-seperator"></div>

                //                                 {
                //                                     i === 4 ?
                //                                         <div className="showmore-container-reverse showless-container">
                //                                             <div className="showmore-button" onClick={() => triggerFunction(false)}>
                //                                                 <div className="discord-button-text">SHOW LESS</div>
                //                                             </div>
                //                                         </div>
                //                                         : null
                //                                 }
                //                             </motion.div>
                //                         </div>
                //                     }
                //                     else {
                //                         return <div className="roadmap-container-reverse">
                //                             <motion.div animate={animation} className="maintain-container roadmap-container-reverse">
                //                                 {/* <div className="roadmap-header">
                //                                     <div className="roadmap-header1">LAUNCH</div>
                //                                     <div className="roadmap-header2">Roadmap</div>
                //                                 </div> */}
                //                                 <div className="roadmap-percent">{v.percent}%</div>
                //                                 <div className="roadmap-subhead">{v.heading}</div>
                //                                 <div className="ghost-desc-text-reverse">{v.desc}</div>
                //                                 <div className="container-seperator"></div>

                //                             </motion.div>
                //                         </div>
                //                     }

                //                 } else if (i <= 2) {
                //                     if (i % 2 == 0) {
                //                         return <div ref={ref} className="roadmap-container">
                //                             <motion.div animate={animation} className="maintain-container">
                //                                 {
                //                                     i === 0 ? (
                //                                         <div className="roadmap-header">
                //                                             <div className="roadmap-header1">LAUNCH</div>
                //                                             <div className="roadmap-header2">Roadmap</div>
                //                                         </div>
                //                                     ) : null
                //                                 }
                //                                 <div className="roadmap-percent">{v.percent}%</div>
                //                                 <div className="roadmap-subhead">{v.heading}</div>
                //                                 <div className="ghost-desc-text">{v.desc}</div>
                //                                 <div className="container-seperator"></div>
                //                                 {
                //                                     i === 2 ?
                //                                         <div className="showmore-container">
                //                                             <div className="showmore-button" onClick={() => triggerFunction(true)}>
                //                                                 <div className="discord-button-text">SHOW MORE</div>
                //                                             </div>
                //                                         </div>
                //                                         : null
                //                                 }
                //                             </motion.div>
                //                         </div>
                //                     }
                //                     else {
                //                         return <div ref={ref} className="roadmap-container-reverse">
                //                             <motion.div animate={animation} className="maintain-container roadmap-container-reverse">
                //                                 {/* <div className="roadmap-header">
                //                                     <div className="roadmap-header1">LAUNCH</div>
                //                                     <div className="roadmap-header2">Roadmap</div>
                //                                 </div> */}
                //                                 <div className="roadmap-percent">{v.percent}%</div>
                //                                 <div className="roadmap-subhead">{v.heading}</div>
                //                                 <div className="ghost-desc-text-reverse">{v.desc}</div>
                //                                 <div className="container-seperator"></div>
                //                             </motion.div>
                //                         </div>
                //                     }
                //                 }
                //             })
                //     }
                // </div>
            }



        </div>
    )
}

export default RoadMap
