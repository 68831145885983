import React, {useState} from 'react';
import Member1 from '../../../assets/Monkeys/monkey11.jpeg'
import Member2 from '../../../assets/Monkeys/monkey12.jpeg'
import Member3 from '../../../assets/Monkeys/monkey2.JPG'
import Member4 from '../../../assets/Monkeys/monkey6.JPG'
import Member5 from '../../../assets/Monkeys/monkey9.jpg'
import { motion } from "framer-motion"
import { useInView } from 'react-intersection-observer';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function CreativeTeam() {
    const [active1, setActive1] = useState(false)
    const [active2, setActive2] = useState(false)
    const [active3, setActive3] = useState(false)
    const [active4, setActive4] = useState(false)
    const [active5, setActive5] = useState(false)
    
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 429 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 428, min: 0 },
            items: 1
        },
    };
    
    return (
        <div  id="section4" className="creativeteam-container">
            <div className="creativeteam-header">
                <div className="creativeheader-top">THE</div>
                <div className="creativeheader-top2">TEAM</div>
            </div>
            {/* <div className="creativeteam-desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div> */}
            <Carousel
                 ssr
                 swipeable
                 responsive={responsive}
                 itemClass="carousel-item-padding-10-px"
                 infinite={true}
                 autoPlay
                 pauseOnHover={false}
                 autoPlaySpeed={2000}
                 arrows={false}
                 className="topcharacter-body">

                <div onHoverStart={() => setActive1(true)} onHoverEnd={() => setActive1(false)} whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }} className="creativeteam-background1">
                    <img className="creativeteam-member1" src={Member1} alt="POLYFIMOS" />
                    <div className="memberdetails-body"  style={{color:'white'}}>
                        <div className="creativemember-text1">Polyfimos</div>
                        <div className="creativemember-text2">Marketing Genius</div>
                    </div>
                </div>
                
                <div onHoverStart={() => setActive2(true)} onHoverEnd={() => setActive2(false)} whileHover={{ scale: 1.1 }}
                    className="creativeteam-background1">
                    <img className="creativeteam-member1" src={Member2} alt="BRONTES" />
                    <div className="memberdetails-body2" style={{ color: 'white'}}>
                        <div className="creativemember-text1">Brontes</div>
                        <div className="creativemember-text2">General Manager</div>
                    </div>
                </div>

                <div onHoverStart={() => setActive3(true)} onHoverEnd={() => setActive3(false)} whileHover={{ scale: 1.1 }}
                     className="creativeteam-background1">
                    <img className="creativeteam-member1" src={Member3} alt="OURANOS" />
                    <div className="memberdetails-body2" style={{ color: 'white'}}>
                        <div className="creativemember-text1">Ouranos</div>
                        <div className="creativemember-text2">Art Director</div>
                    </div>
                </div>

                <div onHoverStart={() => setActive4(true)} onHoverEnd={() => setActive4(false)} whileHover={{ scale: 1.1 }}
                     className="creativeteam-background1">
                    <img className="creativeteam-member1" src={Member4} alt="DANAOS" />
                    <div className="memberdetails-body2" style={{ color: 'white'}}>
                        <div className="creativemember-text1">Danaos</div>
                        <div className="creativemember-text2">Blockchain Expert</div>
                    </div>
                </div>

                <div onHoverStart={() => setActive5(true)} onHoverEnd={() => setActive5(false)} whileHover={{ scale: 1.1 }}
                    className="creativeteam-background1">
                    <img className="creativeteam-member1" src={Member5} alt="ODILON" />
                    <div className="memberdetails-body2" style={{ color: 'white'}}>
                        <div className="creativemember-text1">Odilon</div>
                        <div className="creativemember-text2">Community Expert</div>
                    </div>
                </div>

            </Carousel>
            <div className="ghost-join-button">
                <a href="https://discord.com/invite/YFdUUYZ3yh" target="_target" className="header-button">
                    <div className="header-descord">JOIN OUR DISCORD</div>
                </a>
                {/* <a href="https://discord.com/invite/YFdUUYZ3yh" target="_target" className="descord-button">
                    <div className="discord-button-text">JOIN OUR DISCORD</div>
                </a> */}
            </div>
        </div>
    )
}

export default CreativeTeam
