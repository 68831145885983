import React, {useState} from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Routes,
    Link
} from "react-router-dom";
import Footer from '../components/Footer/footer'
import Header from '../components/Header/header'
import Home from '../Screens/Home/Home'
import Drawer from '../components/Drawer/Drawer'

export default function App() {
    const [drawer, setDrawer ] = useState(false)
    return (
        <Router>
            <Drawer state={{drawer, setDrawer}}/>
            <Header state={{drawer, setDrawer}} />
            <Routes>
                <Route path="/" element={<Home />} />
            </Routes>
            <Footer />
        </Router>
    );
}