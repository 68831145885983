import React, {useEffect} from 'react'
import UniqueGhost from '../../../assets/Home/uniqueGhost.png'
import { motion } from "framer-motion"
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion'

function UniqueGhose() {

    const { ref, inView } = useInView({
        threshold: 0.2
    });
    const animation = useAnimation();

    useEffect(() => {
        if (inView) {
            animation.start({
                x: 0,
                transition: {
                    type: 'spring', duration: 1.5, bounce: 0.3
                }
            })
        }
        if (!inView) {
            animation.start({ x: '100vh' })
        }
    }, [inView])
    
    return (
        <div ref={ref} id="section1" className="unique-ghost-container">

            <div className="ghost-subcontainer1">
                {/* <div className="ghost-header-text">HEY !</div> */}
                <div className="ghost-subheader-text">WELCOME TO THE EXCLUSIVE CYCLOPS MONKEY CLUB.</div>
                <div className="ghost-desc-text">The Cyclops Monkey Club is an exclusive collection of 9.999 NFTs, stored as ERC-721 Tokens on the Ethereum Blockchain and hosted on IPFS. Each NFT is unique and comes with a membership to an exclusive group of successful entrepreneurs, investors, superstars.
                    <br />  <br /> 
                    In a great clearing on the middle island of the Panoptica Chain, the trees and land had grown into a mighty amphitheatre. Here, Polyfimos spoke to all the awake Cyclops Monkeys. He spoke wisely and passionately and asked the warring factions to find common ground. He suggested that they name the place they were meeting ‘The Monkey House’ in honour of their cousins, none of whom had awoken.
                    <br /> <br />
                    The Cyclops Monkeys agreed, some willingly, some more reluctantly than others, and declared the formation of the Cyclops Monkey Club, where they would meet to discuss their differences peacefully. They divided up the islands, with 10.000 ‘awake’ Cyclops Monkeys per island to look after their brothers and sisters who had not awoken and agreed to meet at the Monkey House regularly to keep the peace – at least for now… 
                    </div>
                <div className="ghost-join-button">
                    <a href="https://discord.com/invite/YFdUUYZ3yh" target="_target" className="header-button">
                        <div className="header-descord">JOIN OUR DISCORD</div>
                    </a>
                    {/* <a href="https://discord.com/invite/YFdUUYZ3yh" target="_target" className="descord-button">
                        <div className="discord-button-text">JOIN OUR DISCORD</div>
                    </a> */}
                </div>
            </div>

            <motion.div animate={animation} className="ghost-subcontainer2">
                <img className="ghost-container-image" src={UniqueGhost} alt=""/>
            </motion.div>
            
        </div>
    )
}

export default UniqueGhose
