import React, { useEffect } from "react";
import './footer.css'
import FooterImage from '../../assets/Home/footer.png';
import Logo from '../../assets/logo.png';
import Instagram from '../../assets/instagram.png'
import Crabs from '../../assets/crabs.png'
import Boat from '../../assets/boat.png'
import Twitter from '../../assets/twitter.png'
import { motion } from "framer-motion"
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion'

export default function Footer() {
    const { ref, inView } = useInView({
        threshold: 0.1
    });
    const animation = useAnimation();

    useEffect(() => {
        if (inView) {
            animation.start({
                x: 0,
                transition: {
                    type: 'spring', duration: 1, bounce: 0.3
                }
            })
        }
        if (!inView) {
            animation.start({ x: '50vh' })
        }
    }, [inView])
    return (
        <div className="footer-container">
            <div className="footer-innercontainer">
                <div ref={ref} className="footer-section1">
                    <img className="footer-character" src={FooterImage} alt="Footer Image" />
                </div>
                <motion.div animate={animation} className="footer-section2">
                    <img className="footer-logo" src={Logo} alt="" />
                    
                    <div className="footer-socialmedia-body">

                        <a href="https://twitter.com/cyclops_monkey" target="_target">
                            <img className="social-icons" src={Twitter} alt="Twitter" />
                        </a>

                        <a href="https://discord.com/invite/YFdUUYZ3yh" target="_target">
                            <img className="social-icons" src={Crabs} alt="Instagram" />
                        </a>

                        <a href="https://opensea.io/Cyclops_Monkey_Club" target="_target">
                            <img className="social-icons" src={Boat} alt="Boat" />
                        </a>

                        <a href="https://www.instagram.com/cyclopsmonkey/" target="_target">
                            <img className="social-icons" src={Instagram} alt="Instagram" />
                        </a>

                    </div>
                    {/* <div className="joincommunity-buttoncontainer">
                        <a href="https://discord.com/invite/YFdUUYZ3yh" target="_target" className="joincommunity-button">
                            <div className="joincommunity-button-desc">JOIN OUR DISCORD</div>
                        </a>
                    </div> */}
                    <div className="copyright-font">@Copyright 2021 Cyclops Monkey Club</div>
                </motion.div>
            </div>
        </div>
    );
}