import React from "react";
import {
    Link
} from "react-router-dom";
import './header.css'
import Logo from '../../assets/logo.png'
import joinDescord from '../../assets/joinDescord.png'
import EqualSign from '../../assets/equalSign.png'
import { AiOutlineMenu } from 'react-icons/ai';

export default function App({state}) {
    const {drawer, setDrawer} = state
    return (
        <div className="header-container">
            <a href="#"className="header-subContainer1">
                <img className="header-logo" src={Logo} alt="Logo"/>
            </a>
            <div className="header-subContainer2">
                {/* <div className="header-button">
                    <div className="header-descord">Join Our Discord</div>
                </div> */}
                <div className="header-menu">
                    <a href="http://mint.cyclopsmonkeyclub.com/" target="_target" className="header-button">
                        <div className="header-descord">MINT NOW</div>
                    </a>
                    <AiOutlineMenu style={{cursor:'pointer'}} onClick={() => setDrawer(true)} size={25} color="white" />
                    {/* <img className="header-options" src={EqualSign} alt="Logo"/> */}
                </div>
            </div>
        </div>
    );
}