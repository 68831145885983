import React, { useEffect } from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { motion } from "framer-motion"
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion'

function FrequentlyAsked() {
    const { ref, inView } = useInView({
        threshold: 0.1
    });
    const animation = useAnimation();

    const frequentlyAsked = [
        {
            question: 'WHAT IS AN NFT?',
            answer: 'An NFT (non-fungible token) is a piece of art that lives on the blockchain. Owning an NFT is just like owning a painting, but instead of owning it physically, you own it virtually, on the blockchain. Cyclops Monkey Club is a collection of 10.000 unique NFTs that will live on the Ethereum blockchain. '
        },
        {
            question: 'WHAT IS MINTING?',
            answer: 'Minting a Cyclops Monkey means that you are buying a digital art which becomes a part of the ethereum blockchain. Your digital artwork is represented as an NFT so it can be traded in the market and digitally tracked as it is resold or collected again in the future. '
        },
        {
            question: 'WHICH WALLET CAN I CONNECT TO MINT A CYCLOPS MONEKY?',
            answer: 'You can connect metamask wallets only. If you don’t have it, you create one here : https://metamask.io/ '
        },
        {
            question: 'HOW TO CONNECT MY WALLET?',
            answer: 'After you created your wallet you will just have to click on the button on the header of this page “connect your wallet” and make sure you are on the ethereum network. '
        },
        {
            question: 'HOW MUCH WILL IT COST TO MINT A CYCLOPS MONKEY NFT?',
            answer: 'The PRESALE price is 0.049 ETH + Fees. The PUBLIC SALES price is 0.098 + Fees.'
        },
        {
            question: 'HOW TO MINT? ',
            answer: 'Minting will be announced on our discord group. We will be holding a presale 1 day prior to the public launch for our Whitelist members. Please join our Discord to learn how you can join the Whitelist. The Whitelist spots will be very limited. The public sale will happen 24h after the pre-sale.'
        },
        {
            question: 'WHAT SHOULD I DO AFTER MINTING MY CYCLOPS MONKEY?  ',
            answer: 'You can use your Cyclops Monkey as a profile picture online, and you can also resell your Cyclops Monkey for profit. We advise you to hold it as it will be a part of a great gaming project where you will be able to use it! '
        },
        {
            question: 'WHERE CAN I VIEW MY NFTS AFTER MINTING?',
            answer: 'Once you have minted a Cyclops Monkey NFT, you will be able to see it by connecting your Metamask wallet to OpenSea. '
        },
        {
            question: 'HOW CAN I JOIN THE PRESALE LIST? ',
            answer: 'Join us on Discord, we explain that as well!'
        },
        {
            question: 'HOW CAN I PROMOTE THE PROJECT?',
            answer: 'The best way to promote the project is by inviting people to the Discord channel. If you are an influencer and would like to work with us, write us marketing@cyclopsmonkeyclub.com '
        },
    ]

    useEffect(() => {
        if (inView) {
            animation.start({
                x: 0,
                transition: {
                    type: 'spring', duration: 1, bounce: 0.3
                }
            })
        }
        if (!inView) {
            animation.start({ x: '100vh' })
        }
    }, [inView])
    return (
        <div id="section6" ref={ref} className="frequentlyasked-container">
            <motion.div animate={animation} className="frequentlyasked-container">
                <div className="creativeteam-header">
                    <div className="frequentlyasked-top">FREQUENTLY</div>
                    <div className="creativeheader-top2">ASKED QUESTIONS</div>
                </div>

                <Accordion
                    allowZeroExpanded={true}
                    className="frequentlyasked-body">

                    {
                        frequentlyAsked.map((v, i) =>
                            <AccordionItem>
                                <AccordionItemHeading >
                                    <AccordionItemButton className="topCharacters-heading">
                                        <div className="topCharacter-heading-div">
                                            {v.question}
                                        </div>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p className="topcharacter-desc">
                                        {v.answer}
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                        )
                    }


                </Accordion>
            </motion.div>

        </div>
    )
}

export default FrequentlyAsked
